import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private amplifyService: AmplifyService,
        private router: Router,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.validateLogin();
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.validateLogin();
    }

    async validateLogin() {
        try {
            const session = await this.amplifyService.auth().currentSession();

            /*
             * Optional old code for validating groups.
             * Could be reinstated.
             *

             if (
              session !== null &&
              'cognito:groups' in session.accessToken.payload &&
              session.accessToken.payload['cognito:groups'].includes('administrators')
            ) {
              return true;
            } else {
              this.amplifyService.auth().signOut();
            }

            return false;
            */

            return true;
        } catch (e) {
            this.router.navigate(['/auth']);
            return false;
        }
        return true;
    }
}
