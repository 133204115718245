import { NgModule } from '@angular/core';
import { AmplifyAngularModule } from 'aws-amplify-angular';
import { FormsModule } from '@angular/forms';

import { AuthComponent } from './auth.component';
import { ThemeModule } from '../@theme/theme.module';

import {
  NbLayoutModule,
  NbButtonModule,
  NbThemeModule,
  NbInputModule,
  NbCardModule,
} from '@nebular/theme';

@NgModule({
  imports: [
    AmplifyAngularModule,
    FormsModule,
    ThemeModule,
    NbLayoutModule,
    NbButtonModule,
    NbThemeModule,
    NbInputModule,
    NbCardModule,
  ],
  declarations: [
    AuthComponent,
  ],
})
export class AuthModule { }
