import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'ngx-auth',
  styleUrls: ['auth.component.scss'],
  templateUrl: './auth.component.html',
})
export class AuthComponent implements OnInit {

  signUpConfig: object = {
    hideAllDefaults: true,
    signUpFields: [
      {
        label: 'Name',
        key: 'name',
        required: true,
        displayOrder: 1,
        type: 'string',
      },
      {
        label: 'Email',
        key: 'username',
        required: true,
        displayOrder: 2,
        type: 'string',
      },
      {
        label: 'Password',
        key: 'password',
        required: true,
        displayOrder: 3,
        type: 'password',
      },
    ],
  };

  constructor(
      private amplifyService: AmplifyService,
      private router: Router,
  ) {}

  ngOnInit() {
    this.amplifyService.authStateChange$.subscribe(authState => {
      switch (authState.state) {
        case 'signedIn':
          this.router.navigate(['/dashboard']);
          break;
      }
    });
  }
}
