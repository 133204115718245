import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { AmplifyService } from 'aws-amplify-angular';

import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  environment: any;
  userName: string;
  userMenuOptions: Object[] = [{}];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private amplifyService: AmplifyService,
    private router: Router,
    ) {
  }

  ngOnInit() {
    this.environment = environment;

    this.amplifyService.auth().currentUserInfo().then((data) => {
      this.userName = data.attributes.email;

      this.userMenuOptions = [
        {
          title: 'Logout',
        },
      ];

      this.menuService.onItemClick().subscribe((event) => {
        if (event.item.title === 'Logout') {
          this.amplifyService.auth().signOut().then(() => {
             this.router.navigate(['/auth']);
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
